'use client'

import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Text, Icon, Button } from '@vinted/web-ui'
import { Bookmark24, BookmarkFilled24 } from '@vinted/monochrome-icons'

import { toggleSearchSubscriptionEvent } from 'libs/common/event-tracker/events'

import { toggleCurrentSearchSubscription } from 'state/saved-searches/actions'
import { getCurrentSearch, getIsCatalogSearchButtonLoading } from 'state/saved-searches/selectors'
import { getUserId } from 'state/session/selectors'
import { getCatalogItemErrors } from 'state/items/selectors'
import {
  getGlobalSearchSessionId,
  getIsPopularInCatalog,
  getQuery,
  getSearchSessionId,
} from 'state/catalog-filters/selectors'

import { Screen } from 'constants/tracking/screens'
import useTracking from 'hooks/useTracking/useTracking'
import useBreakpoint from 'hooks/useBreakpoint'
import useTranslate from 'hooks/useTranslate'

const SearchSubscribeButton = () => {
  const dispatch = useDispatch()
  const { track } = useTracking()
  const breakpoints = useBreakpoint()
  const translate = useTranslate()

  const currentSearch = useSelector(getCurrentSearch)
  const query = useSelector(getQuery)
  const errors = useSelector(getCatalogItemErrors)
  const isPopularCatalog = useSelector(getIsPopularInCatalog)
  const searchSessionId = useSelector(getSearchSessionId)
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const userId = useSelector(getUserId)
  const isLoading = useSelector(getIsCatalogSearchButtonLoading)

  const toggleSubscription = () => {
    if (!userId) return

    dispatch(toggleCurrentSearchSubscription({ userId }))

    track(
      toggleSearchSubscriptionEvent({
        savedRecentSearchSessionId: null,
        listName: null,
        savedRecentSearchListId: null,
        screen: Screen.Catalog,
        isSubscribing: !currentSearch?.subscribed,
        searchSessionId,
        searchTitle: currentSearch?.title,
        searchQuery: currentSearch?.search_text ?? query,
        globalSearchSessionId,
      }),
    )
  }

  if (errors || isPopularCatalog || !userId) return null

  const action = currentSearch?.subscribed ? 'delete' : 'save'
  const text = <FormattedMessage id={`saved_searches.actions.${action}`} />

  const a11yLabel = breakpoints.phones ? translate(`saved_searches.a11y.${action}`) : undefined

  return (
    <Button
      isLoading={isLoading}
      text={
        !breakpoints.phones && (
          <Text type={Text.Type.Subtitle} theme="amplified" as="span">
            {text}
          </Text>
        )
      }
      icon={
        <Icon
          name={currentSearch?.subscribed ? BookmarkFilled24 : Bookmark24}
          color={currentSearch?.subscribed ? Icon.Color.Primary : Icon.Color.GreyscaleLevel1}
        />
      }
      onClick={toggleSubscription}
      aria={{
        'aria-label': a11yLabel,
      }}
      testId={`catalog-search-${action}-button`}
      theme="muted"
    />
  )
}

export default SearchSubscribeButton
