import { useCallback, useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UiState } from 'constants/ui'
import { getUserStats } from 'data/api'
import { getUserId } from 'state/session/selectors'
import * as selectors from 'state/user-stats/selectors'
import { actions } from 'state/user-stats/slice'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import { UserStatsContext, UserStatsContextValue } from 'contexts/UserStatsProvider'

const useUserStats = (): UserStatsContextValue => {
  const isInitialFetchingInitiated = useRef(false)
  const dispatch = useDispatch()
  const userStats = useSelector(selectors.getUserStats)
  const requestState = useSelector(selectors.getRequestState)
  const userId = useSelector(getUserId)
  const userStatsContext = useContext(UserStatsContext)
  const isContextApiRefactorEnabled = useFeatureSwitch('user_stats_context_api_refactor')

  if (!userStatsContext) {
    throw new Error('useUserStats must be used within a UserStatsProvider')
  }

  const handleApiResponse = useCallback(
    (response: Awaited<ReturnType<typeof getUserStats>>) => {
      if ('errors' in response) {
        dispatch(actions.getUserStatsFailure())

        return
      }

      dispatch(actions.getUserStatsSuccess({ stats: response.stats }))
    },
    [dispatch],
  )

  const fetchUserStats = useCallback(() => {
    if (!userId) return

    dispatch(actions.getUserStatsRequest())

    getUserStats(userId).then(handleApiResponse)
  }, [dispatch, userId, handleApiResponse])

  const setUnreadMsgCount = useCallback(
    (count: number) => {
      dispatch(
        actions.setUnreadMsgCount({
          count,
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    if (!isContextApiRefactorEnabled) return
    if (userStatsContext.userStats) return
    if (isInitialFetchingInitiated.current) return

    isInitialFetchingInitiated.current = true
    userStatsContext.fetchUserStats()
  }, [isContextApiRefactorEnabled, userStatsContext])

  useEffect(() => {
    if (isContextApiRefactorEnabled) return
    if (userStats) return
    if (requestState !== UiState.Idle) return

    fetchUserStats()
  }, [fetchUserStats, requestState, userStats, isContextApiRefactorEnabled])

  if (isContextApiRefactorEnabled) {
    return userStatsContext
  }

  return { userStats, fetchUserStats, setUnreadMsgCount }
}

export default useUserStats
