'use client'

import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import Google from './setups/Google'
import Pubstack from './setups/Pubstack'
import Amazon from './setups/Amazon'
import Prebid from './setups/Prebid'
import Yieldbird from './setups/Yieldbird'
import Confiant from './setups/Confiant'
import Rokt from './setups/Rokt'

const AdScripts = () => {
  const isYieldBirdScriptEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_ads_yieldbird_script'),
  )
  const isRoktScriptEnabled = useSelector(getIsFeatureSwitchEnabled('web_ads_rokt_script'))

  useEffect(() => {
    window.adPlacements = {}
  }, [])

  return (
    <>
      <Google />
      <Pubstack />
      <Amazon />
      <Prebid />
      {isYieldBirdScriptEnabled && <Yieldbird />}
      {isRoktScriptEnabled && <Rokt />}
      <Confiant />
    </>
  )
}

export default AdScripts
