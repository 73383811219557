'use client'

import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import useTracking from 'hooks/useTracking'
import useUserAgent from 'hooks/useUserAgent'

import {
  AdPlatform,
  AD_REQUEST_CHECK_URL,
  IS_AD_BLOCKER_USED_SESSION_KEY,
  AD_BLOCKER_VISITOR_KEY,
} from 'constants/ads'

import {
  getAdBlockerVisitorId,
  getHasAdBlockerBeenTracked,
  getIsAdBlockerUsed,
} from 'state/ads/selectors'
import { actions } from 'state/ads/slice'

import { adBlockCheckEvent } from 'libs/common/event-tracker/events'
import { setSessionStorageItem } from 'libs/utils/sessionStorage'
import AdManager from 'libs/common/ad-manager'
import { getUserId, getAnonId } from 'state/session/selectors'

const AdvertisementBlockCheck = () => {
  const divRef = useRef<HTMLDivElement>(null)

  const userId = useSelector(getUserId)
  const anonId = useSelector(getAnonId)
  const isAdBlockerUsed = useSelector(getIsAdBlockerUsed)
  const hasAdBlockerBeenTracked = useSelector(getHasAdBlockerBeenTracked)
  const adBlockerVisitorId = useSelector(getAdBlockerVisitorId)
  const visitorId = userId ? String(userId) : anonId

  const dispatch = useDispatch()
  const { track } = useTracking()
  const userAgent = useUserAgent()

  useEffect(() => {
    if (!divRef.current || isAdBlockerUsed !== null) return

    // First check that can be done by checking if AdBlocker
    // loaded its own styles and hidden the `.ads-banner` element

    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    if (divRef.current.children[0]!?.clientHeight > 0) {
      // Then we check if ad request is failing (is blocked)
      // and if it is, then we assume that ad blocker is enabled

      axios
        .head(AD_REQUEST_CHECK_URL, {
          withCredentials: false,
        })
        .then(() => {
          dispatch(actions.setIsAdBlockerUsed({ isAdBlockerUsed: false }))
        })
        .catch(() => {
          dispatch(actions.setIsAdBlockerUsed({ isAdBlockerUsed: true }))
        })
    } else {
      dispatch(actions.setIsAdBlockerUsed({ isAdBlockerUsed: true }))
    }
  }, [dispatch, isAdBlockerUsed])

  useEffect(() => {
    // If it has not been tracked (in this session)
    // and if the value actually is set

    if (hasAdBlockerBeenTracked || isAdBlockerUsed === null) return

    // Track the tracking event for ad block check

    track(
      adBlockCheckEvent({
        isMobileWeb: AdManager.getAdsPlatform(userAgent) === AdPlatform.Mobile,
        isAdBlockerUsed,
      }),
    )

    // Save the value in Redux that event has been tracked

    dispatch(
      actions.setHasAdBlockerBeenTracked({
        hasAdBlockerBeenTracked: true,
      }),
    )

    // Save the value of visitor id in Redux

    dispatch(
      actions.setAdBlockerVisitorId({
        id: visitorId,
      }),
    )

    // Save to session storage that ad blocker is used
    setSessionStorageItem(IS_AD_BLOCKER_USED_SESSION_KEY, String(isAdBlockerUsed))

    // Save visitor id (user id or anon id) to identify
    // when user has logged in or out
    setSessionStorageItem(AD_BLOCKER_VISITOR_KEY, visitorId)
  }, [isAdBlockerUsed, hasAdBlockerBeenTracked, track, dispatch, visitorId, userAgent])

  useEffect(() => {
    // Reset hasAdBlockerBeenTracked value to `false`
    // after user logged in or logged out, to track
    // if particular user is actually using the adblocker

    if (!hasAdBlockerBeenTracked || visitorId === adBlockerVisitorId) return

    dispatch(
      actions.setHasAdBlockerBeenTracked({
        hasAdBlockerBeenTracked: false,
      }),
    )

    dispatch(
      actions.setAdBlockerVisitorId({
        id: visitorId,
      }),
    )
  }, [dispatch, hasAdBlockerBeenTracked, adBlockerVisitorId, visitorId])

  return (
    <div
      ref={divRef}
      tabIndex={-1}
      suppressHydrationWarning
      // work around for ad-blcokers removing html content
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html:
          // We need a dummy div, however
          // we cannot set `display: none`
          // or `visibility: hidden` to make
          // it a valid check, because that's
          // what ad blocker uses to hide these
          // elements
          '<div class="ads-banner" data-testid="ads-banner" style="width:1px;height:1px;position:absolute;pointer-events:none;background:transparent;left:0;top:0"/>',
      }}
    />
  )
}

export default AdvertisementBlockCheck
