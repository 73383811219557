'use client'

import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { noop } from 'lodash'
import classNames from 'classnames'

import { getPlacementByShape, getShouldMockAds, getUiState } from 'state/ads/selectors'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import { AdShape, AdPage } from 'constants/ads'
import { UiState } from 'constants/ui'

import { AdsPlacementModel } from 'types/models'
import useAbTest from 'hooks/useAbTest'

import AdManager from 'libs/common/ad-manager'

import { ConsentGroup } from 'constants/consent'

import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'

import AdContent from './AdContent'
import AdPlaceholder from './AdPlaceholder'
import AdErrorBoundary from './AdErrorBoundary'
import useStickyOptions from './useStickyOptions'
import AdMock from './AdMock'
import { getAdPlacementId } from './utils'

type Props = {
  id?: string
  shape: AdShape
  railsComponent?: boolean
  mediation?: string | null
  config?: AdsPlacementModel
  isManuallyRendered?: boolean
  isManuallyRefreshed?: boolean
  isSidebarAd?: boolean
  onAdRender?: (isAdVisible: boolean) => void
}

function usePlaceholderRemovalTest(shape?: AdShape) {
  let initialShouldRender = true
  const isConsentGroupEnabled = useIsConsentGroupEnabled(ConsentGroup.Targeting)
  const isPlaceholderRemovalTestEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_placeholder_removal_test'),
  )
  const isLeaderboard = useMemo(() => shape === AdShape.Leaderboard, [shape])

  // Expose event should be tracked only if user is about to see a leaderboard ad
  // and when the consent for targeting purposes is not (yet or at all) given,
  // and when the placeholder removal test feature switch is enabled
  const shouldTrackExpose =
    !isConsentGroupEnabled && isLeaderboard && isPlaceholderRemovalTestEnabled

  const placeholderRemovalTest = useAbTest({
    abTestName: 'web_placeholder_removal',
    shouldTrackExpose,
  })

  if (isLeaderboard) {
    if (isConsentGroupEnabled) {
      initialShouldRender = true
    } else if (isPlaceholderRemovalTestEnabled) {
      initialShouldRender = placeholderRemovalTest?.variant === 'off'
    }
  } else {
    initialShouldRender = true
  }

  const [shouldRender, setShouldRender] = useState<boolean>(initialShouldRender)

  useEffect(() => {
    if (!isLeaderboard) return

    // When the test has variant "b", the default behavior is to not render the ad after consent was given.
    // Meaning, that ad (and the placeholder) should be presented only after the full page reload.
    // In case of "off" variant - it will be rendered initially to begin with, so no need for update either.
    if (isPlaceholderRemovalTestEnabled && placeholderRemovalTest?.variant !== 'a') return

    // However, in variant 'a', the default behavior is to render the ad right after consent was given.
    // Hence, this update.
    setShouldRender(isConsentGroupEnabled)
  }, [
    isConsentGroupEnabled,
    isPlaceholderRemovalTestEnabled,
    placeholderRemovalTest,
    isLeaderboard,
  ])

  return shouldRender
}

const Advertisement = ({
  id: propsId,
  shape,
  railsComponent,
  mediation = null,
  config,
  isSidebarAd = false,
  isManuallyRendered = false,
  isManuallyRefreshed = false,
  onAdRender = noop,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isAdRendered, setIsAdRendered] = useState<boolean>(false)

  const shouldMockAds = useSelector(getShouldMockAds)
  const uiState = useSelector(getUiState)
  const placement = useSelector(getPlacementByShape(shape, mediation))

  // TODO: Rework this when we have real Van ads
  // For now it will be used only under the condition
  // that web_ads_van_placement feature switch is enabled
  const shouldShowVanAd = useSelector(getIsFeatureSwitchEnabled('web_ads_van_placement'))
  const vanPlacement = useSelector(getPlacementByShape(shape, 'van'))

  const placementConfig = useMemo(
    () => config || (shouldShowVanAd && vanPlacement) || placement,
    [config, placement, vanPlacement, shouldShowVanAd],
  )

  const shouldRender = usePlaceholderRemovalTest(placementConfig?.shape)

  const id = useMemo(() => propsId || AdManager.generatePlacementId(), [propsId])

  const stickyOptions = useStickyOptions({
    isSticky: placementConfig?.options.isSticky,
    isRailsComponent: railsComponent,
    parentElement: ref.current?.parentElement,
  })

  const handleAdRender = useCallback(
    (isAdVisible: boolean) => {
      onAdRender(isAdVisible)
      setIsAdRendered(isAdVisible)
    },
    [onAdRender],
  )

  if (!placementConfig || uiState !== UiState.Idle || !shouldRender) return null

  return (
    <Suspense>
      <AdErrorBoundary
        pageName={placementConfig.page || AdPage.Unknown}
        placementId={getAdPlacementId(placementConfig)}
      >
        {shouldMockAds ? (
          <AdMock shape={shape} isSidebarAd={isSidebarAd} stickyOptions={stickyOptions} />
        ) : (
          <div
            className={classNames(
              'ad-container',
              `ad-container--${shape}`,
              !!stickyOptions && !railsComponent && 'ad-sticky',
              isAdRendered && 'ad-container--rendered',
              isSidebarAd && 'ad-sidebar',
            )}
            data-testid="advertisement"
            style={railsComponent ? undefined : { top: stickyOptions?.offset }}
            ref={ref}
            suppressHydrationWarning
          >
            <AdPlaceholder shape={shape} platform={placementConfig.platform} />
            <AdContent
              id={id}
              placementConfig={placementConfig}
              isManuallyRefreshed={isManuallyRefreshed}
              isManuallyRendered={isManuallyRendered}
              onAdRender={handleAdRender}
              isAdRendered={isAdRendered}
            />
          </div>
        )}
      </AdErrorBoundary>
    </Suspense>
  )
}

export default Advertisement
