'use client'

import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Dialog, Button, Text, Navigation, Cell, Icon } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'
import * as flagIcons from '@vinted/flag-icons'
import { capitalize } from 'lodash'

import {
  DOMAIN_SELECTED_COOKIE,
  DOMAIN_SELECTED_COOKIE_EXPIRATION_DATE,
} from 'constants/domain_selected_cookie'

import { saveCookie } from 'libs/utils/cookies'
import { CountryLinkDto } from 'types/dtos'
import useLocation from 'hooks/useLocation'
import useAbTest from 'hooks/useAbTest/useAbTest'

type Props = {
  domainLinks: Array<CountryLinkDto>
}

const FLAG_SIZE = 24

function buildFlagName(countryCode: string) {
  return capitalize(countryCode) + FLAG_SIZE
}

const DomainSelectModal = ({ domainLinks }: Props) => {
  const [show, setShow] = useState(true)

  function handleCloseModal() {
    saveCookie(DOMAIN_SELECTED_COOKIE, 'true', DOMAIN_SELECTED_COOKIE_EXPIRATION_DATE)
    setShow(false)
  }

  const currentRelativeUrl = useLocation().relativeUrl

  const isRootPath = currentRelativeUrl === '/'

  const isCountryModalAbTest = useAbTest({
    abTestName: 'country_selection_modal_context_preservation_web',
    shouldTrackExpose: !isRootPath,
  })
  const isCountryModalAbTestEnabled = isCountryModalAbTest?.variant === 'on'

  function renderCountryCell(countryLink: CountryLinkDto, index: number) {
    const flagIconName = flagIcons[buildFlagName(countryLink.code)]
    let relativeUrl = ''

    if (!isRootPath && isCountryModalAbTestEnabled) {
      relativeUrl = currentRelativeUrl
    }

    return (
      <Cell
        key={index}
        body={
          <a className="domain-selection-link" href={countryLink.link + relativeUrl}>
            {flagIconName && <Icon display={Icon.Display.Block} name={flagIconName} />}
            <FormattedMessage id={`native_country_names.${countryLink.code}`} />
          </a>
        }
      />
    )
  }

  if (!domainLinks.length) return null

  return (
    <Dialog show={show} hasScrollableContent testId="domain-select-modal">
      <div className="u-ui-padding-regular u-fill-width">
        <Navigation
          theme="transparent"
          right={
            <Button
              testId="domain-select-modal-close-button"
              styling={Button.Styling.Flat}
              onClick={handleCloseModal}
              iconName={X24}
              inline
            />
          }
        />
        <Cell>
          <Text
            text={<FormattedMessage id="domain_selection.title" />}
            type={Text.Type.Heading}
            as="h2"
          />
        </Cell>
        <div className="u-fill-height u-overflow-auto">{domainLinks.map(renderCountryCell)}</div>
      </div>
    </Dialog>
  )
}

export default DomainSelectModal
