'use client'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Script from 'next/script'

import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'

import { getSegments } from 'state/ads/selectors'

import { ConsentGroup } from 'constants/consent'

import {
  initGoogletag,
  setupGoogletagServices,
  setAdsSegments,
  seNonPersonalizedAds,
} from './utils'

const Google = () => {
  const adsSegments = useSelector(getSegments)
  const hasConsentedTargeting = useIsConsentGroupEnabled(ConsentGroup.Targeting)

  useEffect(() => {
    initGoogletag()
    setupGoogletagServices()
  }, [])

  useEffect(() => {
    seNonPersonalizedAds(!hasConsentedTargeting)
  }, [hasConsentedTargeting])

  useEffect(() => {
    setAdsSegments(adsSegments)
  }, [adsSegments])

  return (
    <Script
      id="googletag-script"
      data-testid="googletag-script"
      src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      strategy="lazyOnload"
    />
  )
}

export default Google
