'use client'

import { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { Spacer } from '@vinted/web-ui'
import classNames from 'classnames'

import AdsProvider from 'containers/AdsProvider'
import ConsentProvider from 'containers/ConsentProvider'

import Advertisement from 'components/Advertisement'
import AdvertisementBlockCheck from 'components/AdvertisementBlockCheck'
import AppBanner from 'components/AppBanner'
import DomainSelectModal from 'components/DomainSelectModal'
import { GoogleAnalyticsTracker } from 'components/Google/Analytics'
import Header from 'components/Header'
import HeaderNoNavigation from 'components/HeaderNoNavigation'
import InAppMessage from 'libs/common/braze/components/InAppMessage'
import MainFooter from 'components/MainFooter'
import PolicyFooter from 'components/PolicyFooter'
import VacationNotification from 'components/VacationNotification'
import GlobalTwoFaModal from 'components/GlobalTwoFAModal'
import ErrorBoundary from 'components/ErrorBoundary'
import FlashMessage from 'components/FlashMessage'

import { AdShape } from 'constants/ads'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { getIsContentOnlyView } from 'state/session/selectors'
import { getIsInCatalog } from 'state/catalog-filters/selectors'
import { CatalogDto, CountryLinkDto, LanguageDto } from 'types/dtos'
import { PageId } from 'types/page-id'
import { SearchBarSearchType } from 'constants/search'
import useIsInPage from 'hooks/useIsInPage'
import useSession from 'hooks/useSession'
import useSystemConfiguration from 'hooks/useSystemConfiguration'

import AdScripts from '../../components/AdScripts'
import { withLayout } from '../../libs/server-utils/hoc'

const policyFooterPages: Array<PageId> = [
  'item-edit',
  'items-new',
  'inbox',
  'inbox-message',
  'settings-security-phone-verification',
  'checkout',
]
const inboxPages: Array<PageId> = ['inbox', 'inbox-message']
const noSpacerBeforeMainFooterPages: Array<PageId> = [
  'pro',
  'about',
  'how-it-works',
  'no-changes-to-taxes',
  'influencer-landing-page',
]

export type Props = {
  languages: Array<LanguageDto>
  catalogTree: Array<CatalogDto>
  isWhiteBackground?: boolean
  shouldBreakoutLayout?: boolean
  hasNoNavigation: boolean
  domainLinks: Array<CountryLinkDto> | null
  children: ReactNode
  serverCookieOptanonConsent?: string
}

const resolveHeaderSearchType = (pathname: string): SearchBarSearchType => {
  const FIRST_VALUE_AFTER_SLASH_INDEX = 1
  const firstValueAfterSlash = pathname.split('/')[FIRST_VALUE_AFTER_SLASH_INDEX]

  if (firstValueAfterSlash === 'member') return SearchBarSearchType.User
  if (firstValueAfterSlash === 'help') return SearchBarSearchType.Faq

  return SearchBarSearchType.Item
}

export const FullPageLayout = ({
  languages,
  catalogTree,
  shouldBreakoutLayout,
  hasNoNavigation,
  domainLinks,
  children,
  isWhiteBackground = true,
  serverCookieOptanonConsent,
}: Props) => {
  const systemConfiguration = useSystemConfiguration()
  const isWebGa4AnalyticsEnabled = useSelector(getIsFeatureSwitchEnabled('web_ga4_analytics'))
  const isContentOnly = useSelector(getIsContentOnlyView)
  const isInCatalog = useSelector(getIsInCatalog)
  const router = useRouter()
  const { isWebview } = useSession()
  const isInPolicyFooterPage = useIsInPage(policyFooterPages)
  const isInInboxPage = useIsInPage(inboxPages)
  const isInNoSpacerBeforeMainFooterPage = useIsInPage(noSpacerBeforeMainFooterPages)

  if (!systemConfiguration) return null

  const renderHeader = () => {
    if (isContentOnly) return null

    if (hasNoNavigation) {
      return <HeaderNoNavigation portal={systemConfiguration.portal} />
    }

    return (
      <Header
        languages={languages}
        catalogTree={catalogTree}
        searchType={resolveHeaderSearchType(router.pathname)}
        isInCatalog={isInCatalog}
        portal={systemConfiguration.portal}
        impressumUrl={systemConfiguration.urls.impressum}
        isBusinessAccountLinksVisible={systemConfiguration.businessAccountInformationalLinksVisible}
        isOurPlatformVisible={!!systemConfiguration.urls.ourPlatform}
      />
    )
  }

  const renderFooter = () => {
    if (isContentOnly) return null

    if (!isInPolicyFooterPage && !hasNoNavigation) {
      return (
        <>
          {!isInNoSpacerBeforeMainFooterPage && <Spacer size={Spacer.Size.X5Large} />}
          <div className="u-ui-margin-top-auto">
            <MainFooter
              isBusinessInfoLinksEnabled={
                systemConfiguration.businessAccountInformationalLinksVisible
              }
              isDigitalLawEnabled={!!systemConfiguration.urls.ourPlatform}
              onlineComplaintUrl={systemConfiguration.urls.onlineComplaints}
            />
          </div>
        </>
      )
    }

    return (
      <PolicyFooter
        isDigitalLawEnabled={!!systemConfiguration.urls.ourPlatform}
        isBusinessInfoLinksEnabled={systemConfiguration.businessAccountInformationalLinksVisible}
        onlineComplaintUrl={systemConfiguration.urls.onlineComplaints}
        isStatic={isInInboxPage}
        noDivider={isInInboxPage}
      />
    )
  }

  return (
    <ConsentProvider serverCookieOptanonConsent={serverCookieOptanonConsent}>
      <AdsProvider>
        {/*
        It is important to keep `AdScripts` above
        `Advertisement` component to initialize various
        third party scripts like googletag or etc.
        that are necessary for `Advertisement` component
        to actually render an advertisement.
      */}
        <AdScripts />

        <div
          className={classNames('fullpage-layout is-wide', {
            'u-background-white': isWhiteBackground,
          })}
        >
          <InAppMessage />
          {domainLinks && <DomainSelectModal domainLinks={domainLinks} />}
          {renderHeader()}

          <main className="site">
            <div className="site-wrapper">
              <ErrorBoundary FallbackComponent={ErrorBoundary.LayoutError}>
                <Advertisement shape={AdShape.Leaderboard} id="ad-leaderboard" />

                <section className="site-content">
                  <div className={classNames({ container: !shouldBreakoutLayout })}>
                    <div className={classNames({ container: shouldBreakoutLayout })}>
                      {!isWebview && <VacationNotification />}
                    </div>

                    <div className="row u-position-relative">
                      <section id="content" className="site-content__content-section grid12">
                        <div className="body-content">
                          <FlashMessage />
                          {children}
                        </div>
                      </section>
                    </div>
                  </div>
                </section>

                <AppBanner />
                <AdvertisementBlockCheck />
                {isWebGa4AnalyticsEnabled && <GoogleAnalyticsTracker />}
              </ErrorBoundary>
            </div>
          </main>

          {renderFooter()}
          <GlobalTwoFaModal />
        </div>
      </AdsProvider>
    </ConsentProvider>
  )
}

export default withLayout<Props>(FullPageLayout)
